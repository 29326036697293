<template>
    <span :class="{
                'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5':true,
                'bg-gray-100 text-gray-800':state === 'concept' || state === 'closed',
                'bg-pink-100 text-pink-800':state === 'scheduled',
                'bg-green-100 text-green-800':state === 'running',
                'bg-custom-100 text-custom-800':state === 'finished',
                'bg-gray-500 text-white':state === 'paused',
            }">
              {{ $translate(`campaign.statuses.${state}`) }}
            </span>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "CampaignStateBadge",
        props: {
            state: {
                type: String,
                default: 'concept'
            }
        }
    })
</script>