import {Store} from "@/store/store";
import {SocialChannel} from "@/interfaces/social"

export interface Social {
    channels: SocialChannel[]
}

class SocialStore extends Store <Social> {
    protected data(): Social {
        return {
            channels: [
                {
                    channel: 'facebook',
                    name: 'Facebook',
                    icon: 'fab fa-facebook',
                    backgroundColor: '#3b5998'
                } as SocialChannel, {
                    channel: 'pinterest',
                    name: 'Pinterest',
                    icon: 'fab fa-pinterest',
                    backgroundColor: '#BD081C'
                } as SocialChannel, {
                    channel: 'instagram',
                    name: 'Instagram',
                    icon: 'fab fa-instagram',
                    backgroundColor: '#405DE6'
                } as SocialChannel, {
                    channel: 'twitter',
                    name: 'Twitter',
                    icon: 'fab fa-twitter',
                    backgroundColor: '#1DA1F2'
                } as SocialChannel, {
                    channel: 'youtube',
                    name: 'YouTube',
                    icon: 'fab fa-youtube',
                    backgroundColor: '#FF0000'
                } as SocialChannel, {
                    channel: 'general',
                    name: 'General',
                    icon: 'fal fa-location',
                    backgroundColor: '#1c63f1'
                } as SocialChannel
            ],
        };
    }

    public getChannel(key: string): SocialChannel {
        const channel = this.getState().channels.filter((channel: SocialChannel) => channel.channel === key);
        if (channel.length === 1) {
            return channel[0];
        }
        return {
            channel: '',
            name: '',
            icon: '',
            backgroundColor: '#000'
        } as SocialChannel;
    }
}


export const socialStore: SocialStore = new SocialStore();