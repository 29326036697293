
    import {defineComponent} from 'vue';
    import {socialStore} from "@/store/social";

    export default defineComponent({
        name: "SocialActionItem",
        emits: ['open'],
        props: {
            open: {
                type: Boolean,
                default: false,
            },
            completed: {
                type: Boolean,
                default: false,
            },
            channel: {
                type: String,
                default: '',
            },
            name: {
                type: String,
                default: '',
            },
            badge: {
                type: String,
                default: '',
            },
        },
        setup(props, {emit}) {
            const clicked = () => emit('open');
            return {
                clicked,
                socialStore
            }
        }
    })
