export interface IChartDataSet {
    type: 'line' | 'bar',
    label: string,
    data: number[],
    borderColor: string;
    backgroundColor: string;
    borderWidth: number;
    order: number;
}

export interface IChartSerie {
    key: string,
    label: string,
    type: typeof BaseChartDataSet,
    order: number,
    rgb: { r: number, g: number, b: number, alpha: number },
}

export class BaseChartDataSet implements IChartDataSet {
    type: "line" | "bar" = 'line';
    label: string = '';
    data: number[] = [];
    borderColor: string = 'gray';
    backgroundColor: string = 'gray';
    borderWidth: number = 1;
    order: number = 1;

    constructor(name: string) {
        this.label = name;
    }

    clearPoints(): this {
        this.data = [];
        return this;
    }

    setOrder(order: number): this {
        this.order = order;
        return this;
    }

    addPoint(point: number): this {
        this.data.push(point);
        return this;
    }

    addPoints(points: number[]): this {
        this.data = [...this.data, ...points];
        return this;
    }

    setColor(r: number, g: number, b: number, opacity: number = 1): this {
        this.borderColor = `rgba(${r},${g},${b},${opacity})`;
        this.backgroundColor = `rgba(${r},${g},${b},${opacity})`;
        return this;
    }
}

export class LineChartDataSet extends BaseChartDataSet {
    type: "line" | "bar" = 'line';
    tension: number = 0.5;
    borderWidth: number = 2;

    constructor(name: string = 'Line serie') {
        super(name);
    }
}

export class BarChartDataSet extends BaseChartDataSet {
    type: "line" | "bar" = 'bar';

    constructor(name: string = 'Bar serie') {
        super(name);
    }
}