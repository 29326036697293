<template>
  <div class="pb-10">
    <div class="bg-custom-100 pt-10 pb-24">
      <div class="max-w-7xl mx-auto px-6 lg:px-8">
        <Header :name="$translate('campaign.detail')"
                :previous="$translate('campaign.overview.title')"
                @on-previous="router.push({name:'dashboard'})">
          <div class="relative">
            <div class="inline-flex shadow-sm rounded-md divide-x divide-custom-600"
                 v-if="campaign.state === 'running'">
              <button type="button"
                      @click.prevent="pauseCampaign"
                      class="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-custom-600 group focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-custom-500">
                <div
                    class="relative inline-flex items-center bg-custom-500 pl-3 pr-2 border border-transparent rounded-md shadow-sm text-white">
                  <span class=" py-2"><i class="far fa-pause"></i></span>
                  <p class="mx-2.5 text-sm font-medium">
                    Pause campaign
                  </p>
                </div>
              </button>
            </div>
            <div class="inline-flex shadow-sm rounded-md divide-x divide-custom-600" v-if="campaign.state === 'paused'">
              <button type="button"
                      @click.prevent="resumeCampaign"
                      class="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-custom-600 group focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-custom-500">
                <div
                    class="relative inline-flex items-center bg-custom-500 pl-3 pr-2 border border-transparent rounded-md shadow-sm text-white">
                  <span class=" py-2"><i class="far fa-play"></i></span>
                  <p class="mx-2.5 text-sm font-medium">
                    Resume campaign
                  </p>
                </div>
              </button>
            </div>
          </div>
        </Header>
      </div>
    </div>
    <div class="py-10 -mt-24">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="grid grid-cols-3 gap-10">
          <div class="md:col-span-2 col-span-3">
            <div class="bg-white shadow sm:rounded-lg overflow-hidden" v-if="campaign.loading">
              <Loading :loading="campaign.loading"/>
            </div>
            <div class="bg-white shadow sm:rounded-lg overflow-hidden" v-if="!campaign.loading">
              <CardHeader :name="campaign.name" class="p-2 border-b border-gray-200">
                <template #actions>
                  <CampaignStateBadge :state="campaign.state"/>
                </template>
              </CardHeader>
              <div class="px-4 py-5 sm:p-6 sm:pt-4">
                <dl class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $translate('campaign.giveaway-goal') }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ $translate(`campaign.reasons.${campaign.reason}`) }}
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $translate('campaign.number-of-products') }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ campaign.products }}
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $translate('campaign.overview.start_date') }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ campaign.start_date }}
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $translate('campaign.overview.end_date') }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ campaign.end_date }}
                    </dd>
                  </div>
                  <div class="sm:col-span-2" v-if="campaign.reason !== 'brand-audience'">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $translate('campaign.overview.hashtags') }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                                            <span v-for="(hashtag, hashtagIndex) in campaign.hashtags"
                                                  :key="`category_${hashtagIndex}`"
                                                  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-2 mb-2">
                                                    {{ hashtag }}
                                                </span>
                    </dd>
                  </div>
                  <div class="sm:col-span-1" v-if="campaign.reason !== 'brand-audience'">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $translate('campaign.overview.post_instructions') }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ campaign.post_instructions }}
                    </dd>
                  </div>
                  <div class="sm:col-span-1" v-if="campaign.reason !== 'brand-audience'">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $translate('campaign.overview.image_instructions') }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ campaign.image_instructions }}
                    </dd>
                  </div>
                  <div class="sm:col-span-2" v-if="campaign.reason !== 'brand-audience'">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $translate('campaign.categories.title') }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                                            <span v-for="(category, index) in campaign.categories"
                                                  :key="`category_${index}`"
                                                  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-2 mb-2">
                                                    {{ category }}
                                                </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div class="bg-white shadow sm:rounded-lg overflow-hidden mt-10" v-if="!campaign.loading">
              <Tabs v-model="activeTab">
                <Tab :name="$translate('campaign.actions.title')">
                  <div class="px-4 py-5 sm:px-6 sm:pt-4 border-b border-gray-200" v-if="socialActions.length > 0">
                    <div class="grid grid-cols-1 gap-2">
                      <div class="flex items-center justify-between">
                        <span class="flex-grow flex flex-col" id="availability-label">
                          <span class="text-sm font-medium text-gray-900">Group by platform</span>
                          <span class="text-sm text-gray-500">Show the social action results grouped by platform</span>
                        </span>
                        <button type="button"
                                :class="{
                                  'bg-gray-200': !groupedResults,
                                  'bg-custom-600': groupedResults,
                                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500': true
                                }"
                                role="switch" aria-checked="false" aria-labelledby="availability-label"
                                @click.prevent="groupedResults = !groupedResults">
                          <span aria-hidden="true"
                                :class="{
                                  'translate-x-0': !groupedResults,
                                  'translate-x-5': groupedResults,
                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200': true
                                }">
                          </span>
                        </button>
                      </div>

                    </div>
                  </div>
                  <Empty
                      description="This overview with the number of completed social actions is updated daily as long as the campaign is active."
                      v-if="socialActions.length === 0">
                    <template #image>
                      <img src="../../assets/images/actions.svg" class="h-32 m-auto mt-16 mb-8"/>
                    </template>
                  </Empty>
                  <div class="px-4 py-5 sm:p-6 sm:pt-4">
                    <div class="grid grid-cols-1 gap-2">
                      <SocialActionItem
                          v-for="(action, index) in (groupedResults ? socialActionsByPlatform : socialActions)"
                          :key="`action_${index}`"
                          :channel="action.platform"
                          :name="groupedResults ? $translate(`social.${action.platform}`) : $translate(`social.actions.${action.platform}.${action.action}`)"
                          :badge="$translate(`social.completed.${action.completed === 1 ? `singular` : `plural`}`, {count: action.completed})"/>
                    </div>
                  </div>
                </Tab>
                <Tab :name="$translate('campaign.surveys.responses')" v-if="campaign.reason !== 'brand-audience'">
                  <DataTable :table="surveys.table" :dataset="surveys.list" :border-top="false"
                             empty="campaign.surveys.empty">
                    <template #column_responses_count="{value}">
                      {{
                        $translate(`campaign.surveys.response.${value === 1 ? `singular` :
                            `plural`}`, {responses: value}).toLowerCase()
                      }}
                    </template>
                    <template #image>
                      <img src="../../assets/images/survey.svg" class="h-32 m-auto mt-16 mb-8"/>
                    </template>
                  </DataTable>
                </Tab>
                <Tab :name="$translate('campaign.participants.participants')"
                     :badge="participantsTable.page.results"
                     v-if="campaign.reason === 'brand-audience'">
                  <DataTable :table="participantsTable" :dataset="participants" :border-top="false"
                             empty="campaign.participants.empty">
                    <template #column_status="{row}">
                      <div>
                        <span
                            class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800"
                            v-if="row.is_winner && !row.is_winner_expired">{{ $translate(`campaign.participants.winner`) }}</span>
                        <span
                            class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-100 text-gray-800"
                            v-if="row.is_winner && row.is_winner_expired">{{ $translate(`campaign.participants.winner_expired`) }}</span>
                      </div>
                    </template>
                    <template #column_progress="{row}">
                      {{ row.number_of_completed_actions }}
                      {{
                        $translate(`campaign.participants.action.${row.number_of_completed_actions === 1 ? `singular` : `plural`}`)
                      }}
                      /
                      {{ row.number_of_points_earned }}
                      {{
                        $translate(`campaign.participants.point.${row.number_of_points_earned === 1 ? `singular` : `plural`}`)
                      }}
                    </template>
                    <template #image>
                      <img src="../../assets/images/participants.svg" class="h-32 m-auto mt-16 mb-8"/>
                    </template>
                  </DataTable>
                </Tab>
              </Tabs>
            </div>
            <div class="bg-white shadow sm:rounded-lg overflow-hidden mt-10">
              <CardHeader name="Insights"
                          description="View progress of your campaign here">
                <div class="pt-5" v-if="chartLabels.length > 0">
                  <LineBarChart :labels="chartLabels" :datasets="chartDatasets"/>
                </div>
                <Empty
                    description="Insights are not yet available, insights will be updated daily as long as the campaign is running."
                    v-if="chartLabels.length === 0">
                  <template #image>
                    <img src="../../assets/images/chart.svg" class="h-32 m-auto mt-16 mb-8"/>
                  </template>
                </Empty>
              </CardHeader>
            </div>
          </div>
          <div class="md:col-span-1 col-span-3">
            <div class="bg-white shadow sm:rounded-lg overflow-hidden mb-10"
                 v-if="campaign.reason === 'brand-audience'">
              <CardHeader name="Share your campaign"
                          :description="campaign.shareUrl ? 'Share the URL below with your audience so they can participate in your campaign.' : 'We are creating your campaign. In about an hour, you can find and share the URL of your campaign with your audience here.'">
                <div class="pt-5" v-if="campaign.shareUrl">
                  <div class="max-w-lg flex rounded-md shadow-sm cursor-pointer">
                    <input type="text" name="url" id="url" autocomplete="false" disabled v-model="campaign.shareUrl"
                           class="flex-1 block w-full focus:ring-custom-500 focus:border-custom-500 min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300">
                    <span
                        class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                        @click="copyUrl">
                        <i class="fal fa-clone"></i>
                      </span>
                  </div>
                </div>
              </CardHeader>
            </div>

            <div class="bg-white shadow sm:rounded-lg overflow-hidden mb-10" v-if="timeline.length > 0 && false">
              <div class="p-5">
                <div class="flow-root">
                  <ul class="-mb-8">
                    <li v-for="(event, index) in timeline" :key="`timeline_${index}`">
                      <div class="relative pb-8">
                                                <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                                      aria-hidden="true" v-if="index < timeline.length - 1"></span>
                        <div class="relative flex items-start space-x-3">
                          <div>
                            <div class="relative px-1">
                              <div
                                  class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <i :class="event.icon"></i>
                              </div>
                            </div>
                          </div>
                          <div class="min-w-0 flex-1">
                            <div>
                              <div class="text-sm">
                                <a href="#" class="font-medium text-gray-900">
                                  {{
                                    $translate(`campaign.timeline.${event.event}.${event.count
                                        === 1 ? `singular` : `plural`}`,
                                        {count: event.count})
                                  }}
                                </a>
                              </div>
                              <p class="mt-0.5 text-sm text-gray-500">
                                {{ event.diff }}
                              </p>
                            </div>
                            <div class="mt-2 text-sm text-gray-700">
                              <p>{{ event.description }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bg-white shadow sm:rounded-lg overflow-hidden mb-10" v-if="!campaign.loading">
              <div class="bg-white overflow-hidden rounded-md">
                <a :href="product.url" target="_blank" class="block">
                  <div class="flex items-center px-4 py-4">
                    <div class="min-w-0 flex-1 flex items-center">
                      <div class="flex-shrink-0 bg-white p-2 rounded-md border border-gray-50">
                        <img class="h-12 w-12"
                             :src="product.image"
                             alt="">
                      </div>
                      <div class="min-w-0 flex-1 px-4">
                        <div>
                          <p class="text-sm font-medium text-custom-600 truncate">
                            {{ product.name }}
                          </p>
                          <p class="mt-2 flex items-center text-sm text-gray-500">
                            <span class="truncate">{{ product.marketplace }}</span>
                          </p>
                        </div>
                      </div>
                      <span
                          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-custom-100 text-custom-800">
                                                {{
                          new Intl.NumberFormat(locale, {
                            style: 'currency',
                            currency: product.currency
                          }).format(product.price)
                        }}
                                            </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="bg-white shadow sm:rounded-lg overflow-hidden mb-10"
                 v-if="campaign.reason === 'brand-audience'">
              <CardHeader name="How does this campaign work?">
                <div class="mt-4 prose prose-custom prose-sm text-gray-500 mx-auto">
                  <ul>
                    <li>Share the <a href="#" @click.prevent="showShareModal">campaign URL</a> with your own audience.
                    </li>
                    <li>Mark participants as winners in the <a href="#" @click.prevent="activeTab = 1">participants
                      tab</a>.
                    </li>
                    <li>Four days after the campaign ends we will automatically invite the remaining number of
                      participants as winners.
                    </li>
                  </ul>
                  <p>A participant that you invite as a winner will receive an invitation to purchase your product with
                    a 100% cashback guarantee. The winner must use the invitation within 24 hours. </p>
                  <p><em>The maximum number of winners is equal to the number of products {{ campaign.products }} that
                    you have entered in this giveaway campaign.</em></p>
                </div>
              </CardHeader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SurveyResults
      v-model:visible="survey.visible"
      :id="survey.id"
      :name="survey.title"
  />
  <Modal :modal="shareModal">
    <div class="block w-full">
      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
          Share you campaign
        </h3>
        <Loading :loading="campaign.loading"/>
        <div v-if="!campaign.loading" class="mt-2 text-sm text-gray-500 prose">
          <p v-if="campaign.shareUrl">
            Share the URL below with your audience so they can participate in your campaign
          </p>
          <p v-if="!campaign.shareUrl">
            We are creating your campaign. When this is completed you will find the campaign URL here.
          </p>
        </div>
        <div v-if="!campaign.loading && campaign.shareUrl" class="pt-3">
          <div class="max-w-lg flex rounded-md shadow-sm cursor-pointer">
            <input type="text" name="url" autocomplete="false" disabled v-model="campaign.shareUrl"
                   class="flex-1 block w-full focus:ring-custom-500 focus:border-custom-500 min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300">
            <span
                class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                @click="copyUrl">
                        <i class="fal fa-clone"></i>
                      </span>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
        <button type="button"
                @click.prevent="shareModalHide"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-custom-600 hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
          Close
        </button>
      </span>
        <span v-if="!campaign.shareUrl && !campaign.loading"
              class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button type="button"
                  @click="fetch(false)"
                  class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
              Request campaign URL
          </button>
      </span>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import {defineComponent, reactive, ref, Ref} from 'vue';
import {getErrorMessage, useAxios} from "@/plugins/axios";
import {useNotification} from "@/plugins/notification";
import {BaseAction, BaseColumn, BaseTable, LinkAction, Pagination, TextColumn} from "@/types/table";
import {Category} from "@/interfaces/campaign";
import router from "@/router";
import {useRouterHelper} from "@/plugins/routerHelper";
import SocialActionItem from "@/pages/campaigns/SocialActionItem.vue";
import SurveyResults from "@/pages/survey/SurveyResults.vue";
import {userStore} from "@/store/user";
import {ISurvey} from '@/interfaces/survey';
import CampaignStateBadge from "@/pages/campaigns/CampaignStateBadge.vue";
import {IParticipant, Participant} from "@/interfaces/participants";
import {useTranslation} from '@/plugins/i18n';
import {useClipboard} from "@/plugins/clipboard";
import LineBarChart from "@/components/charts/LineBarChart.vue";
import {BarChartDataSet, IChartDataSet, IChartSerie, LineChartDataSet} from "@/interfaces/chart/chart";
import {usePromise} from "@/components/usePromise";

export interface Hashtag {
  hashtag: string
}

export default defineComponent({
  name: "CampaignDetail ",
  components: {
    SocialActionItem,
    SurveyResults,
    CampaignStateBadge,
    LineBarChart,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    campaignId: {
      type: String,
      default: '',
    }
  },
  emits: ['update:visible'],
  setup(props, {emit}) {

    const axios = useAxios();

    const notification = useNotification();
    const routerHelper = useRouterHelper();

    const activeTab: Ref<number> = ref(0);
    const groupedResults: Ref<boolean> = ref(true);

    const actionTable = reactive(new BaseTable(Pagination.None))
    actionTable.addColumn(new TextColumn('platform', 'social.platform'));
    actionTable.addColumn(new TextColumn('action', 'social.action'));
    actionTable.addColumn(new TextColumn('points', 'social.points'));

    const campaign = reactive({
      loading: false,
      name: '',
      reason: '',
      actions: [],
      categories: [],
      post_instructions: '',
      image_instructions: '',
      hashtags: [],
      products: 0,
      start_date: '',
      end_date: '',
      actionId: '',
      state: '',
      shareUrl: '',
    });

    const product = reactive({
      name: '',
      marketplace: '',
      price: 0,
      currency: 'USD',
      url: '',
      image: ''
    });

    const survey = reactive({
      visible: false,
      id: '',
      title: ''
    });

    const participants: Ref<Array<IParticipant>> = ref([]);

    const timeline: Ref<Array<{
      event: string,
      icon: string,
      diff: string,
      count: number,
      description: string
    }>> = ref([]);

    const surveys = reactive({
      table: new BaseTable(Pagination.None)
          .addColumn(new BaseColumn('title', 'campaign.surveys.survey'))
          .addColumn(new BaseColumn('responses_count', 'campaign.surveys.response.title'))
          .addAction(new BaseAction('test').setIcon('fal fa-poll-h').disable((item: ISurvey) => {
            return item.responses_count === 0;
          }).onClick((item: ISurvey) => {
            survey.id = item.id;
            survey.visible = true;
            survey.title = item.title;
          })),
      list: []
    });

    const participantsTable: Ref<BaseTable> = ref(new BaseTable(Pagination.Numbers));

    //  On table page changed, fetch another result page
    participantsTable.value.onChangedPage((page: number) => {
      fetchParticipants(page).then(() => {
      }).catch(() => {
      })
    });

    participantsTable.value
        .addColumn(new BaseColumn('name', 'campaign.participants.name'))
    participantsTable.value
        .addColumn(new BaseColumn('progress', 'campaign.participants.progress').hideMobile())
        .addColumn(new BaseColumn('status', '').preventTranslate().hideMobile())
        .addAction(new LinkAction('Select as winner').preventTranslate().setIcon('fal fa-trophy').invisible((participant: IParticipant) => participant.is_winner).onClick((participant: IParticipant) => inviteWinner(participant)))

    const socialActionsByPlatform: Ref<Array<{
      platform: string,
      action: string,
      completed: number
    }>> = ref([]);

    const socialActions: Ref<Array<{
      platform: string,
      action: string,
      completed: number
    }>> = ref([]);

    /**
     * Share campaign URL modal
     */
    const shareModal = reactive(new usePromise());
    const showShareModal = async () => {
      const confirmed = await shareModal.show();
      if (confirmed) {
        shareModal.hide();
      }
    }

    const shareModalHide = () => shareModal.hide();
    const shareInterval: Ref<number | undefined> = ref(undefined);
    const shareIntervalCount: Ref<number> = ref(0);

    //  When campaign is just created an a brand audience campaign, show share campaign URL modal
    if (routerHelper.getQueryParameter('created') === 'brand-audience') {
      showShareModal();
      //  Start interval to make sure that Share URL is present

      shareInterval.value = setInterval(() => {
        if (shareIntervalCount.value > 10 || campaign.shareUrl) {
          clearInterval(shareInterval.value);
          return;
        }
        fetch(false);
        shareIntervalCount.value++;
      }, 1000);
    }

    const fetchParticipants = (page: number): Promise<any> => {
      return new Promise((resolve, reject) => {
        axios.get(`campaign/${routerHelper.getParameter('id')}/participants?page=${page}`)
            .then(response => response.data)
            .then((response) => {

              participants.value = [];
              response?.data?.forEach((participantDetail: any) => {
                const participant = new Participant();
                participant.id = participantDetail.id;
                participant.name = participantDetail.name;
                participant.number_of_completed_actions = participantDetail.total_actions;
                participant.number_of_points_earned = participantDetail.total_points;
                participant.is_winner = participantDetail.is_winner;
                participant.is_winner_expired = participantDetail.is_winner_expired;
                participants.value.push(participant);
              })

              participantsTable.value.page.results = response?.meta?.total ?? 0;

              participantsTable.value.setResultsPerPage(response?.meta?.per_page ?? 10);
              participantsTable.value.setResults(response?.meta?.total ?? 0);
              participantsTable.value.loading = campaign.loading;
              resolve();
            })
            .catch(() => {
              participantsTable.value.loading = campaign.loading;
              reject();
            });
      });
    }

    const fetch = (withLoading: boolean = true) => {
      //  Load campaign
      campaign.loading = withLoading;

      //  Get campaign
      Promise.all([
        axios.get(`campaign/${routerHelper.getParameter('id')}`),
        axios.get(`campaign/${routerHelper.getParameter('id')}/hashtags`),
      ]).then(results => {

        campaign.loading = false;

        const campaignDetails = results[0].data;
        campaign.name = campaignDetails.campaign.name;
        campaign.reason = campaignDetails.campaign.reason;
        campaign.actions = campaignDetails.campaign.campaign_actions;
        campaign.categories = campaignDetails.campaign.categories.map((category: Category) => category.caption);
        campaign.products = campaignDetails.campaign.number_of_products;
        campaign.start_date = campaignDetails.campaign.start_date;
        campaign.end_date = campaignDetails.campaign.end_date;
        campaign.post_instructions = campaignDetails.campaign.post_instruction ?? 'Not provided';
        campaign.image_instructions = campaignDetails.campaign.image_instruction ?? 'Not provided';
        campaign.state = campaignDetails.campaign.state ?? 'concept';
        campaign.shareUrl = campaignDetails.campaign?.ip_campaign?.public_url || '';

        if (campaign.reason === 'brand-audience') {
          fetchParticipants(1).then(() => {
          }).catch(() => {
          });
        }

        socialActions.value = [];
        (campaignDetails.campaign.campaign_actions_statistics ?? []).forEach((action: any) => {
          socialActions.value.push({
            platform: action.platform,
            action: action.action,
            completed: action.count,
          });
        });
        socialActions.value = socialActions.value.sort((a, b) => b.completed - a.completed);

        socialActionsByPlatform.value = [];
        (campaignDetails.campaign.campaign_actions_statistics_by_platform ?? []).forEach((action: any) => {
          socialActionsByPlatform.value.push({
            platform: action.platform,
            action: action.action,
            completed: action.count,
          });
        });
        socialActionsByPlatform.value = socialActionsByPlatform.value.sort((a, b) => b.completed - a.completed);

        product.name = campaignDetails.campaign.product?.name;
        product.marketplace = campaignDetails.campaign.channel?.name;
        product.price = campaignDetails.campaign.product?.price ?? 0;
        product.currency = campaignDetails.campaign.product?.currency ?? 'USD';
        product.url = campaignDetails.campaign.product?.url;
        product.image = campaignDetails.campaign.product?.image_url;

        surveys.list = campaignDetails.campaign.surveys;


        const hashtagDetails = results[1].data;
        campaign.hashtags = hashtagDetails.hashtags.map((hashtag: Hashtag) => hashtag.hashtag);

        Object.keys(campaignDetails.campaign.timeline_stats ?? {}).forEach((key: string) => {
          const event = campaignDetails.campaign.timeline_stats[key];
          Object.keys(event).forEach((key: string) => {
            let icon: string = '';
            switch (key) {
              case 'products_giveaway':
                icon = 'fal fa-gifts';
                break;
              case 'influencers_invited':
                icon = 'fal fa-user-crown';
                break;
              case 'campaign_launched':
                icon = 'fal fa-rocket-launch';
                break;
              case 'campaign_created':
                icon = 'fal fa-bullhorn';
                break;
            }
            timeline.value.push({
              event: key,
              diff: event[key].diff ?? '',
              count: event[key].count ?? 0,
              icon,
              description: '',
            });
          })
        });

      }).catch(error => {
        console.log('error', error);
        campaign.loading = false;
        notification.error('', getErrorMessage(error.response.data));
      });
    };

    fetch();

    const inviteWinner = (participant: IParticipant) => {
      //  Check if max invitations is reached
      axios.put(`campaign/${routerHelper.getParameter('id')}/winner`, {participation_id: participant.id}).then(() => {
        fetch();
        notification.success(
            i18n.translate(`campaign.participants.invited.title`),
            i18n.translate(`campaign.participants.invited.description`, {name: participant.name})
        );
      }).catch(() => {
      })
    }

    const series: Ref<Array<IChartSerie>> = ref([
      {
        key: 'visits',
        label: 'Vistis',
        rgb: {r: 255, g: 164, b: 8, alpha: 1},
        type: BarChartDataSet,
        order: 4
      },
      {
        key: 'sessions',
        label: 'Sessions',
        rgb: {r: 255, g: 213, b: 140, alpha: 1},
        type: BarChartDataSet,
        order: 3
      },
      {
        key: 'clicks',
        label: 'Clicks',
        rgb: {r: 245, g: 195, b: 110, alpha: 0.96},
        type: BarChartDataSet,
        order: 2
      },
      {
        key: 'number_of_actions',
        label: 'Completed actions',
        rgb: {r: 77, g: 121, b: 168, alpha: 0.66},
        type: LineChartDataSet,
        order: 1
      },
      {
        key: 'number_of_points',
        label: 'Points',
        rgb: {r: 110, g: 175, b: 245, alpha: 0.96},
        type: LineChartDataSet,
        order: 0
      },
    ])
    const chartLabels: Ref<Array<String>> = ref([]);
    const chartDatasets: Ref<Array<IChartDataSet>> = ref([]);

    const fetchChart = () => {
      axios.get(`campaign/${routerHelper.getParameter('id')}/statistics`)
          .then(response => response.data)
          .then(response => {

            series.value.forEach((serie: IChartSerie) => {
              if (response[serie.key]) {
                //  Fill chart labels from first serie
                if (chartLabels.value.length === 0) {
                  chartLabels.value = Object.keys(response[serie.key] ?? {});
                }
                //  Set serie datapoints
                chartDatasets.value.push(new serie.type(serie.label)
                    .setOrder(serie.order)
                    .setColor(serie.rgb.r, serie.rgb.g, serie.rgb.b, serie.rgb.alpha)
                    .addPoints(Object.keys(response[serie.key] ?? {}).map((key: string) => {
                      return response[serie.key][key];
                    })));
              }
            })
          }).catch(() => {
      })
    }

    fetchChart();

    const clipboard = useClipboard();
    const i18n = useTranslation();
    const copyUrl = () => {
      clipboard.copy(campaign.shareUrl);
      notification.success(i18n.translate('campaign.copy.title'), i18n.translate('campaign.copy.description'))
    }

    const pauseCampaign = () => {
      axios.put(`campaign/${routerHelper.getParameter('id')}/pause`)
          .then(() => fetch(false))
          .catch(() => {
          });
    }

    const resumeCampaign = () => {
      axios.put(`campaign/${routerHelper.getParameter('id')}/resume`)
          .then(() => fetch(false))
          .catch(() => {
          });

    }

    return {
      shareModal,
      showShareModal,
      shareModalHide,
      activeTab,
      groupedResults,
      locale: userStore.getState().locale,
      router,
      campaign,
      product,
      actionTable,
      survey,
      surveys,
      timeline,
      participantsTable,
      participants,
      copyUrl,
      chartLabels,
      chartDatasets,
      socialActionsByPlatform,
      socialActions,
      fetch,
      pauseCampaign,
      resumeCampaign,
    }

  }
})
</script>