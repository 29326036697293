
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "CampaignStateBadge",
        props: {
            state: {
                type: String,
                default: 'concept'
            }
        }
    })
