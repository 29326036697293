<template>
    <div
            class="border border-gray-200 shadow-sm overflow-hidden rounded-md">
        <a href="#" class="col-span-1 flex" @click.prevent="clicked()">
            <div class="flex-shrink-0 flex items-center justify-center w-12 bg-custom-500 text-white text-lg font-medium"
                 :style="`background: ${socialStore.getChannel(channel).backgroundColor}`">
                <i :class="socialStore.getChannel(channel).icon"
                   v-if="socialStore.getChannel(channel).icon"></i>
            </div>
            <div class="flex-1 flex items-center justify-between bg-white rounded-r-md truncate">
                <div class="flex-1 px-4 py-3 text-sm truncate">
                    <span class="text-gray-900 font-medium hover:text-gray-600">{{ name }}</span>
                </div>
                <div class="flex px-4">
                    <span :class="{
                        'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium':true,
                        'bg-gray-100 text-gray-800': !completed,
                        'bg-green-100 text-green-800': completed
                    }">
                        {{ badge }}
                    </span>
                </div>
            </div>
        </a>
        <div class="border-t border-gray-200" v-if="open">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import {socialStore} from "@/store/social";

    export default defineComponent({
        name: "SocialActionItem",
        emits: ['open'],
        props: {
            open: {
                type: Boolean,
                default: false,
            },
            completed: {
                type: Boolean,
                default: false,
            },
            channel: {
                type: String,
                default: '',
            },
            name: {
                type: String,
                default: '',
            },
            badge: {
                type: String,
                default: '',
            },
        },
        setup(props, {emit}) {
            const clicked = () => emit('open');
            return {
                clicked,
                socialStore
            }
        }
    })
</script>