export enum ParticipantStatus {
    Participant,
    Winner
}

export interface IParticipant {
    id: string | number,
    name: string,
    is_winner: boolean,
    is_winner_expired: boolean,
    number_of_completed_actions: number,
    number_of_points_earned: number,
    status: ParticipantStatus
}

export class Participant implements IParticipant {
    id: string | number = '';
    is_winner: boolean = false;
    is_winner_expired: boolean = false;
    name: string = '';
    number_of_completed_actions: number = 0;
    number_of_points_earned: number = 0;
    status: ParticipantStatus = 0;
}