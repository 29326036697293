
import {defineComponent, reactive, ref, Ref} from 'vue';
import {getErrorMessage, useAxios} from "@/plugins/axios";
import {useNotification} from "@/plugins/notification";
import {BaseAction, BaseColumn, BaseTable, LinkAction, Pagination, TextColumn} from "@/types/table";
import {Category} from "@/interfaces/campaign";
import router from "@/router";
import {useRouterHelper} from "@/plugins/routerHelper";
import SocialActionItem from "@/pages/campaigns/SocialActionItem.vue";
import SurveyResults from "@/pages/survey/SurveyResults.vue";
import {userStore} from "@/store/user";
import {ISurvey} from '@/interfaces/survey';
import CampaignStateBadge from "@/pages/campaigns/CampaignStateBadge.vue";
import {IParticipant, Participant} from "@/interfaces/participants";
import {useTranslation} from '@/plugins/i18n';
import {useClipboard} from "@/plugins/clipboard";
import LineBarChart from "@/components/charts/LineBarChart.vue";
import {BarChartDataSet, IChartDataSet, IChartSerie, LineChartDataSet} from "@/interfaces/chart/chart";
import {usePromise} from "@/components/usePromise";

export interface Hashtag {
  hashtag: string
}

export default defineComponent({
  name: "CampaignDetail ",
  components: {
    SocialActionItem,
    SurveyResults,
    CampaignStateBadge,
    LineBarChart,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    campaignId: {
      type: String,
      default: '',
    }
  },
  emits: ['update:visible'],
  setup(props, {emit}) {

    const axios = useAxios();

    const notification = useNotification();
    const routerHelper = useRouterHelper();

    const activeTab: Ref<number> = ref(0);
    const groupedResults: Ref<boolean> = ref(true);

    const actionTable = reactive(new BaseTable(Pagination.None))
    actionTable.addColumn(new TextColumn('platform', 'social.platform'));
    actionTable.addColumn(new TextColumn('action', 'social.action'));
    actionTable.addColumn(new TextColumn('points', 'social.points'));

    const campaign = reactive({
      loading: false,
      name: '',
      reason: '',
      actions: [],
      categories: [],
      post_instructions: '',
      image_instructions: '',
      hashtags: [],
      products: 0,
      start_date: '',
      end_date: '',
      actionId: '',
      state: '',
      shareUrl: '',
    });

    const product = reactive({
      name: '',
      marketplace: '',
      price: 0,
      currency: 'USD',
      url: '',
      image: ''
    });

    const survey = reactive({
      visible: false,
      id: '',
      title: ''
    });

    const participants: Ref<Array<IParticipant>> = ref([]);

    const timeline: Ref<Array<{
      event: string,
      icon: string,
      diff: string,
      count: number,
      description: string
    }>> = ref([]);

    const surveys = reactive({
      table: new BaseTable(Pagination.None)
          .addColumn(new BaseColumn('title', 'campaign.surveys.survey'))
          .addColumn(new BaseColumn('responses_count', 'campaign.surveys.response.title'))
          .addAction(new BaseAction('test').setIcon('fal fa-poll-h').disable((item: ISurvey) => {
            return item.responses_count === 0;
          }).onClick((item: ISurvey) => {
            survey.id = item.id;
            survey.visible = true;
            survey.title = item.title;
          })),
      list: []
    });

    const participantsTable: Ref<BaseTable> = ref(new BaseTable(Pagination.Numbers));

    //  On table page changed, fetch another result page
    participantsTable.value.onChangedPage((page: number) => {
      fetchParticipants(page).then(() => {
      }).catch(() => {
      })
    });

    participantsTable.value
        .addColumn(new BaseColumn('name', 'campaign.participants.name'))
    participantsTable.value
        .addColumn(new BaseColumn('progress', 'campaign.participants.progress').hideMobile())
        .addColumn(new BaseColumn('status', '').preventTranslate().hideMobile())
        .addAction(new LinkAction('Select as winner').preventTranslate().setIcon('fal fa-trophy').invisible((participant: IParticipant) => participant.is_winner).onClick((participant: IParticipant) => inviteWinner(participant)))

    const socialActionsByPlatform: Ref<Array<{
      platform: string,
      action: string,
      completed: number
    }>> = ref([]);

    const socialActions: Ref<Array<{
      platform: string,
      action: string,
      completed: number
    }>> = ref([]);

    /**
     * Share campaign URL modal
     */
    const shareModal = reactive(new usePromise());
    const showShareModal = async () => {
      const confirmed = await shareModal.show();
      if (confirmed) {
        shareModal.hide();
      }
    }

    const shareModalHide = () => shareModal.hide();
    const shareInterval: Ref<number | undefined> = ref(undefined);
    const shareIntervalCount: Ref<number> = ref(0);

    //  When campaign is just created an a brand audience campaign, show share campaign URL modal
    if (routerHelper.getQueryParameter('created') === 'brand-audience') {
      showShareModal();
      //  Start interval to make sure that Share URL is present

      shareInterval.value = setInterval(() => {
        if (shareIntervalCount.value > 10 || campaign.shareUrl) {
          clearInterval(shareInterval.value);
          return;
        }
        fetch(false);
        shareIntervalCount.value++;
      }, 1000);
    }

    const fetchParticipants = (page: number): Promise<any> => {
      return new Promise((resolve, reject) => {
        axios.get(`campaign/${routerHelper.getParameter('id')}/participants?page=${page}`)
            .then(response => response.data)
            .then((response) => {

              participants.value = [];
              response?.data?.forEach((participantDetail: any) => {
                const participant = new Participant();
                participant.id = participantDetail.id;
                participant.name = participantDetail.name;
                participant.number_of_completed_actions = participantDetail.total_actions;
                participant.number_of_points_earned = participantDetail.total_points;
                participant.is_winner = participantDetail.is_winner;
                participant.is_winner_expired = participantDetail.is_winner_expired;
                participants.value.push(participant);
              })

              participantsTable.value.page.results = response?.meta?.total ?? 0;

              participantsTable.value.setResultsPerPage(response?.meta?.per_page ?? 10);
              participantsTable.value.setResults(response?.meta?.total ?? 0);
              participantsTable.value.loading = campaign.loading;
              resolve();
            })
            .catch(() => {
              participantsTable.value.loading = campaign.loading;
              reject();
            });
      });
    }

    const fetch = (withLoading: boolean = true) => {
      //  Load campaign
      campaign.loading = withLoading;

      //  Get campaign
      Promise.all([
        axios.get(`campaign/${routerHelper.getParameter('id')}`),
        axios.get(`campaign/${routerHelper.getParameter('id')}/hashtags`),
      ]).then(results => {

        campaign.loading = false;

        const campaignDetails = results[0].data;
        campaign.name = campaignDetails.campaign.name;
        campaign.reason = campaignDetails.campaign.reason;
        campaign.actions = campaignDetails.campaign.campaign_actions;
        campaign.categories = campaignDetails.campaign.categories.map((category: Category) => category.caption);
        campaign.products = campaignDetails.campaign.number_of_products;
        campaign.start_date = campaignDetails.campaign.start_date;
        campaign.end_date = campaignDetails.campaign.end_date;
        campaign.post_instructions = campaignDetails.campaign.post_instruction ?? 'Not provided';
        campaign.image_instructions = campaignDetails.campaign.image_instruction ?? 'Not provided';
        campaign.state = campaignDetails.campaign.state ?? 'concept';
        campaign.shareUrl = campaignDetails.campaign?.ip_campaign?.public_url || '';

        if (campaign.reason === 'brand-audience') {
          fetchParticipants(1).then(() => {
          }).catch(() => {
          });
        }

        socialActions.value = [];
        (campaignDetails.campaign.campaign_actions_statistics ?? []).forEach((action: any) => {
          socialActions.value.push({
            platform: action.platform,
            action: action.action,
            completed: action.count,
          });
        });
        socialActions.value = socialActions.value.sort((a, b) => b.completed - a.completed);

        socialActionsByPlatform.value = [];
        (campaignDetails.campaign.campaign_actions_statistics_by_platform ?? []).forEach((action: any) => {
          socialActionsByPlatform.value.push({
            platform: action.platform,
            action: action.action,
            completed: action.count,
          });
        });
        socialActionsByPlatform.value = socialActionsByPlatform.value.sort((a, b) => b.completed - a.completed);

        product.name = campaignDetails.campaign.product?.name;
        product.marketplace = campaignDetails.campaign.channel?.name;
        product.price = campaignDetails.campaign.product?.price ?? 0;
        product.currency = campaignDetails.campaign.product?.currency ?? 'USD';
        product.url = campaignDetails.campaign.product?.url;
        product.image = campaignDetails.campaign.product?.image_url;

        surveys.list = campaignDetails.campaign.surveys;


        const hashtagDetails = results[1].data;
        campaign.hashtags = hashtagDetails.hashtags.map((hashtag: Hashtag) => hashtag.hashtag);

        Object.keys(campaignDetails.campaign.timeline_stats ?? {}).forEach((key: string) => {
          const event = campaignDetails.campaign.timeline_stats[key];
          Object.keys(event).forEach((key: string) => {
            let icon: string = '';
            switch (key) {
              case 'products_giveaway':
                icon = 'fal fa-gifts';
                break;
              case 'influencers_invited':
                icon = 'fal fa-user-crown';
                break;
              case 'campaign_launched':
                icon = 'fal fa-rocket-launch';
                break;
              case 'campaign_created':
                icon = 'fal fa-bullhorn';
                break;
            }
            timeline.value.push({
              event: key,
              diff: event[key].diff ?? '',
              count: event[key].count ?? 0,
              icon,
              description: '',
            });
          })
        });

      }).catch(error => {
        console.log('error', error);
        campaign.loading = false;
        notification.error('', getErrorMessage(error.response.data));
      });
    };

    fetch();

    const inviteWinner = (participant: IParticipant) => {
      //  Check if max invitations is reached
      axios.put(`campaign/${routerHelper.getParameter('id')}/winner`, {participation_id: participant.id}).then(() => {
        fetch();
        notification.success(
            i18n.translate(`campaign.participants.invited.title`),
            i18n.translate(`campaign.participants.invited.description`, {name: participant.name})
        );
      }).catch(() => {
      })
    }

    const series: Ref<Array<IChartSerie>> = ref([
      {
        key: 'visits',
        label: 'Vistis',
        rgb: {r: 255, g: 164, b: 8, alpha: 1},
        type: BarChartDataSet,
        order: 4
      },
      {
        key: 'sessions',
        label: 'Sessions',
        rgb: {r: 255, g: 213, b: 140, alpha: 1},
        type: BarChartDataSet,
        order: 3
      },
      {
        key: 'clicks',
        label: 'Clicks',
        rgb: {r: 245, g: 195, b: 110, alpha: 0.96},
        type: BarChartDataSet,
        order: 2
      },
      {
        key: 'number_of_actions',
        label: 'Completed actions',
        rgb: {r: 77, g: 121, b: 168, alpha: 0.66},
        type: LineChartDataSet,
        order: 1
      },
      {
        key: 'number_of_points',
        label: 'Points',
        rgb: {r: 110, g: 175, b: 245, alpha: 0.96},
        type: LineChartDataSet,
        order: 0
      },
    ])
    const chartLabels: Ref<Array<String>> = ref([]);
    const chartDatasets: Ref<Array<IChartDataSet>> = ref([]);

    const fetchChart = () => {
      axios.get(`campaign/${routerHelper.getParameter('id')}/statistics`)
          .then(response => response.data)
          .then(response => {

            series.value.forEach((serie: IChartSerie) => {
              if (response[serie.key]) {
                //  Fill chart labels from first serie
                if (chartLabels.value.length === 0) {
                  chartLabels.value = Object.keys(response[serie.key] ?? {});
                }
                //  Set serie datapoints
                chartDatasets.value.push(new serie.type(serie.label)
                    .setOrder(serie.order)
                    .setColor(serie.rgb.r, serie.rgb.g, serie.rgb.b, serie.rgb.alpha)
                    .addPoints(Object.keys(response[serie.key] ?? {}).map((key: string) => {
                      return response[serie.key][key];
                    })));
              }
            })
          }).catch(() => {
      })
    }

    fetchChart();

    const clipboard = useClipboard();
    const i18n = useTranslation();
    const copyUrl = () => {
      clipboard.copy(campaign.shareUrl);
      notification.success(i18n.translate('campaign.copy.title'), i18n.translate('campaign.copy.description'))
    }

    const pauseCampaign = () => {
      axios.put(`campaign/${routerHelper.getParameter('id')}/pause`)
          .then(() => fetch(false))
          .catch(() => {
          });
    }

    const resumeCampaign = () => {
      axios.put(`campaign/${routerHelper.getParameter('id')}/resume`)
          .then(() => fetch(false))
          .catch(() => {
          });

    }

    return {
      shareModal,
      showShareModal,
      shareModalHide,
      activeTab,
      groupedResults,
      locale: userStore.getState().locale,
      router,
      campaign,
      product,
      actionTable,
      survey,
      surveys,
      timeline,
      participantsTable,
      participants,
      copyUrl,
      chartLabels,
      chartDatasets,
      socialActionsByPlatform,
      socialActions,
      fetch,
      pauseCampaign,
      resumeCampaign,
    }

  }
})
