<template>
    <Drawer :drawer="surveyResultsDrawer" :title="name"
            :description="$translate('campaign.surveys.results.title')" width="max-w-3xl">
        <div class="divide-y divide-gray-200">
            <div v-for="(section, index) in survey.sections" :key="`section_${index}`">
                <div class="px-4 py-5 sm:p-6 border-b border-gray-200 bg-gray-50">
                    <h2 class="text-sm leading-6 font-medium text-gray-900">
                        {{ section.title }}
                    </h2>
                    <p class="mt-1 max-w-2xl text-xs text-gray-500">
                        {{ section.description }}
                    </p>
                </div>
                <div class="divide-y divide-gray-200">
                    <div class="px-4 sm:px-6 py-2"
                         v-for="(question, qIndex) in section.questions"
                         :key="`section_${index}_${qIndex}`">
                        <dt class="text-sm font-medium text-gray-500">
                            {{ question.question }}
                        </dt>
                        <dd :class="{'mt-1 text-sm' : true, 'text-gray-900':question.answer, 'text-gray-300':!question.answer}">
                            {{ question.answer ?? 'No response' }}
                        </dd>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Pagination
                    :pagination="pagination"
                    label="Showing {from} of {total} responses"
                    @onPageChanged="pageChanged"
            />
        </template>
    </Drawer>
</template>

<script lang="ts">
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {useAxios} from "@/plugins/axios";
    import {Paging} from "@/interfaces/pagination";
    import {ISurveySection, Survey, SurveySection} from '@/interfaces/survey';

    export default defineComponent({
        name: "SurveyResults",
        props: {
            id: {
                type: String,
                default: '',
            },
            visible: {
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                default: '',
            }
        },
        emits: ['update', 'update:visible'],
        setup: function (props, {emit}) {

            const axios = useAxios();

            const surveyResultsDrawer = reactive(new usePromise());
            const surveyResults = async () => {
                const confirmed = await surveyResultsDrawer.show();
                if (confirmed) {
                    await surveyResults();
                } else {
                    emit('update:visible', false);
                }
            };

            const pagination = reactive(new Paging());

            const survey = reactive(new Survey());

            const fetch = (page: number = 1) => {
                axios.get(`/survey/${props.id}/result?page=${page}`)
                    .then(response => response.data)
                    .then(response => {
                        Object.assign(pagination, response.meta);

                        survey.sections = [];
                        if (response.data.length === 1) {
                            (response.data[0].sections ?? []).forEach((section: {
                                title: string,
                                description: string | null,
                                questions: Array<{ question: string, answers: Array<{ answer: string }> }>
                            }) => {
                                const questions: Array<{ question: string, answer: string | null }> = [];
                                section.questions.forEach((question: { question: string, answers: Array<{ answer: string }> }) => {
                                    questions.push({
                                        question: question.question,
                                        answer: question.answers.length > 0 ? question.answers[0].answer : null
                                    })
                                })
                                survey.sections.push(Object.assign({
                                    title: section.title,
                                    description: section.description,
                                    questions: questions,
                                }, SurveySection))
                            });
                        }
                    })
                    .catch((error) => {

                    });
            }

            watch(() => props.visible, (visible) => {
                if (visible) {
                    fetch();
                    surveyResults();
                }
            }, {deep: true, immediate: true})

            const pageChanged = (page: number) => fetch(page);

            return {
                survey,
                pagination,
                surveyResultsDrawer,
                pageChanged
            };

        }
    })
</script>