export interface ISurveyQuestion {
    question: string
}

export class SurveyQuestion implements ISurveyQuestion {
    question: string = ''
}

export interface ISurveySection {
    title: string,
    description: string | null,
    questions: ISurveyQuestion[],
}

export class SurveySection implements ISurveySection {
    title: string = '';
    description: string | null = '';
    questions: ISurveyQuestion[] = [];
}

export interface ISurvey {
    id: string,
    title: string,
    responses_count: number,
    sections: ISurveySection[]
}

export class Survey implements ISurvey {
    id: string = '';
    responses_count: number = 0;
    sections: ISurveySection[] = [];
    title: string = '';

}