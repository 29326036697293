

import {defineComponent, onMounted, PropType} from 'vue';
import {Chart, ChartItem, registerables} from "chart.js";
import {IChartDataSet} from "@/interfaces/chart/chart";

export default defineComponent({
  name: "Test",
  props: {
    datasets: {
      type: Object as PropType<Array<IChartDataSet>>,
      default: () => []
    },
    labels: {
      type: Object as PropType<Array<String>>,
      default: () => []
    }
  },
  setup(props) {
    onMounted(() => {
      Chart.register(...registerables);
      renderChart();
    });
    const renderChart = () => {
      const ctx = document.getElementById('myChart') as ChartItem;
      new Chart(ctx, {
        type: 'line',
        data: {
          datasets: props.datasets,
          labels: props.labels
        },
        options: {
          interaction: {
            mode: 'index',
            axis: 'x'
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    }
  }
})
