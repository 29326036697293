
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {useAxios} from "@/plugins/axios";
    import {Paging} from "@/interfaces/pagination";
    import {ISurveySection, Survey, SurveySection} from '@/interfaces/survey';

    export default defineComponent({
        name: "SurveyResults",
        props: {
            id: {
                type: String,
                default: '',
            },
            visible: {
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                default: '',
            }
        },
        emits: ['update', 'update:visible'],
        setup: function (props, {emit}) {

            const axios = useAxios();

            const surveyResultsDrawer = reactive(new usePromise());
            const surveyResults = async () => {
                const confirmed = await surveyResultsDrawer.show();
                if (confirmed) {
                    await surveyResults();
                } else {
                    emit('update:visible', false);
                }
            };

            const pagination = reactive(new Paging());

            const survey = reactive(new Survey());

            const fetch = (page: number = 1) => {
                axios.get(`/survey/${props.id}/result?page=${page}`)
                    .then(response => response.data)
                    .then(response => {
                        Object.assign(pagination, response.meta);

                        survey.sections = [];
                        if (response.data.length === 1) {
                            (response.data[0].sections ?? []).forEach((section: {
                                title: string,
                                description: string | null,
                                questions: Array<{ question: string, answers: Array<{ answer: string }> }>
                            }) => {
                                const questions: Array<{ question: string, answer: string | null }> = [];
                                section.questions.forEach((question: { question: string, answers: Array<{ answer: string }> }) => {
                                    questions.push({
                                        question: question.question,
                                        answer: question.answers.length > 0 ? question.answers[0].answer : null
                                    })
                                })
                                survey.sections.push(Object.assign({
                                    title: section.title,
                                    description: section.description,
                                    questions: questions,
                                }, SurveySection))
                            });
                        }
                    })
                    .catch((error) => {

                    });
            }

            watch(() => props.visible, (visible) => {
                if (visible) {
                    fetch();
                    surveyResults();
                }
            }, {deep: true, immediate: true})

            const pageChanged = (page: number) => fetch(page);

            return {
                survey,
                pagination,
                surveyResultsDrawer,
                pageChanged
            };

        }
    })
